<cdx-modal
    (closeEmitter)="closeModal()"
    #modal
    *ngIf="modalData"
    [center]="true"
    [fitContent]="modalData.fitContent"
    [size]="modalData.modalSize"
>
    <div class="genericModal-content">
        <cdx-svg-icon
            *ngIf="modalData.icon"
            [name]="modalData.icon"
            [fill]="modalData.iconFill"
            [size]="modalData.isNewTextStyle? 'smaller':'medium'"
        ></cdx-svg-icon>
        <div class="content-block" [ngStyle]="{'text-align': modalData.align}">
            <h4 *ngIf="modalData.isNewTextStyle" id="dialog-title">{{ modalData.title }}</h4>
            <h3 *ngIf="!modalData.isNewTextStyle" id="dialog-title">{{ modalData.title }}</h3>
            <ng-content></ng-content>
            <ng-container *ngIf="modalData.description && modalData.hasMultipleParagraphDesc; else simpleDesc">
                <div [innerHTML]="modalData.description" ></div>
            </ng-container>
            <ng-template  #simpleDesc>
                <p *ngIf="modalData.description" [ngClass]="{'content-text':modalData.isNewTextStyle}">{{ modalData.description }}</p>
            </ng-template>
        </div>
        <div class="genericModal-actions">
            <button
                *ngIf="modalData.hasActionButton"
                [fillStyle]="modalData.hasCancelButton ||modalData.isButtonPrimary? 'primary' : 'outline'"
                 cdxButton
                [fullWidth]="modalData.isNewTextStyle"
                autoFocus
                (click)="ctaEmit()"
                i18n="@@error-okButton"
            >
                {{ modalData.buttonText }}
            </button>
            <button
                *ngIf="modalData.hasCancelButton"
                fillStyle="outline"
                cdxButton
                [fullWidth]="modalData.isNewTextStyle"
                (click)="closeModal()"
                i18n="@@error-cancelButton"
            >
                {{ modalData.cancelButtonText }}
            </button>
            <button
                *ngIf="modalData.hasLinkButton"
                fillStyle="link"
                cdxButton
                (click)="closeModal()"
                class="actionButtonLink"
            >
                {{ modalData.linkButtonText }}
            </button>
        </div>
    </div>
</cdx-modal>