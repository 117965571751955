import { Inject, Injectable } from '@angular/core';
import { WINDOW, WindowRef } from './window.service';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor(@Inject(WINDOW) private window: WindowRef) {}

	get hasLocalStorage(): boolean {
		const window = <any>this.window;
		return !!window.localStorage;
	}

	get localStorage(): any {
		const window = <any>this.window;
		return window.localStorage;
	}

	setItem(name: string, value: string): false | undefined {
		if (!this.hasLocalStorage) {
			return false;
		}

		this.localStorage.setItem(name, value);
	}

	getItem(name: string): any {
		if (!this.hasLocalStorage) {
			return;
		}

		const item = this.localStorage.getItem(name);

		if (!item) {
			return;
		}

		try {
			return JSON.parse(item);
		} catch (e) {
			return item;
		}
	}

	removeItem(name: string): false | undefined {
		if (!this.hasLocalStorage) {
			return false;
		}

		this.localStorage.removeItem(name);
	}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const StorageKeys: { [id: string]: string } = {
	purchaseByQuantityAcknowledged: 'purchaseByQuantityAcknowledged',
	changeOrderModalAcknowledged: 'changeOrderModalAcknowledged',
	changedPriceTutorialAcknowledged: 'changedPriceTutorialAcknowledged',
	ignoreChangedPriceTutorialAcknowledgement: 'ignoreChangedPriceTutorialAcknowledgement',
	changedPriceTutorialMobileWebAcknowledged: 'changedPriceTutorialMobileWebAcknowledged',
	ageRestrictedProductAcknowledged: 'ageRestrictedProductAcknowledged',
};
